import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {WithSearch} from '@elastic/react-search-ui';

import {PageWrapper, SearchResults} from '../components/templates';
import {SearchInputInline} from '../components/organisms';
import {Column, LayoutContainer} from '../components/layouts';
import {RCDLStateContext} from '../contexts/RCDLcontext';
import {useHomeSearchHandles} from '../contexts/searchContext';

/**
 * @description Page template for 'search results' page.
 * @param {object} pageContext Page data from gatsby queries.
 * @return {DOMElement} Search page React element.
 */
const Search = ({pageContext}) => {
  let [currentPagination, setCurrentPagination] = useState(1);
  let [maxPagination, setMaxPagination] = useState(1);
  const {setSearchResultFilter, filterValue} = useHomeSearchHandles();

  return (
    <RCDLStateContext.Consumer>
      {RCDLState => {
        if (typeof window !== 'undefined') {
          return (
            <WithSearch
              mapContextToProps={({
                resultSearchTerm,
                setSearchTerm,
                searchTerm,
                setResultsPerPage,
                totalResults,
                results,
                autocompletedResults,
                onSubmit
              }) => ({
                resultSearchTerm,
                setSearchTerm,
                searchTerm,
                setResultsPerPage,
                totalResults,
                results,
                autocompletedResults,
                onSubmit
              })}
            >
              {({
                resultSearchTerm,
                setSearchTerm,
                searchTerm,
                setResultsPerPage,
                totalResults,
                results,
                autocompletedResults,
                onSubmit
              }) => {
                let paginationTotal = totalResults;
                setResultsPerPage(300);
                if (paginationTotal > 300) {
                  paginationTotal = 300;
                }

                return (
                  <PageWrapper pageContext={{...pageContext, title: 'Search'}}>
                    <>
                      <LayoutContainer
                        columns="one"
                        additionalClass="rc-max-width--xl rc-text--center
                      rc-padding-bottom--md rc-padding-top--md rc-margin-bottom--md"
                      >
                        <Column>
                          <h1 className="rc-alpha rc-margin-top--md">
                            {resultSearchTerm ? `Results for '${resultSearchTerm}'` : 'Search'}
                          </h1>
                          <SearchInputInline
                            initialFocus
                            setSearchTerm={setSearchTerm}
                            searchTerm={searchTerm}
                            liClasses="rc-padding--xs"
                            ulClasses="rc-list rc-list--align rc-list--blank rc-bg-colour--brand4 rc-padding--xs"
                            RCDLState={RCDLState}
                            onSubmit={onSubmit}
                            currentFilter={filterValue}
                            setFilter={setSearchResultFilter}
                            autocompletedResults={autocompletedResults}
                          />
                          <div className="rc-margin-top--lg" />
                        </Column>
                      </LayoutContainer>
                      <SearchResults
                        currentPagination={currentPagination}
                        setCurrentPagination={setCurrentPagination}
                        maxPagination={maxPagination}
                        setMaxPagination={setMaxPagination}
                        results={results}
                        totalResults={totalResults}
                      />
                    </>
                  </PageWrapper>
                );
              }}
            </WithSearch>
          );
        }
        return null;
      }}
    </RCDLStateContext.Consumer>
  );
};

Search.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default Search;
